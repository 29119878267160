<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20">
      <FormItem>
        <span class="label">产品名称：</span>
        <Select class="iviewIptWidth250 marginRight60" placeholder="请输入" clearable filterable v-model="queryFrom.productName">
          <Option v-for="(item, index) in productSelect" :value="item.name" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span class="label">有效期：</span>
        <DatePicker type="date" placeholder="请选择" @on-change="changeTime($event, 1)" clearable></DatePicker>
        <span class="centeFont">至</span>
        <DatePicker type="date" class="iviewIptWidth250 marginRight60" placeholder="请选择" v-model="queryFrom.end_time_end" :options="options" @on-change="changeTime($event, 2)" clearable></DatePicker>
      </FormItem>
      <FormItem>
        <span class="label">状态：</span>
        <Select class="iviewIptWidth250 marginRight0" placeholder="请输入" clearable filterable v-model="queryFrom.status_code">
          <Option v-for="item in statusCode" :value="item.code" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="po-create-number" :label-width="20">
        <span class="pageBtn finger btnSure" @click="query">查询</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="add" v-if="showChange == 1" v-show="insertAble">新增</span>
      </FormItem>
    </Form>
    <Table @on-sort-change="sortChange" :productList="listColumns" :productData="listData" :pages="pages" :total="total" totalText="条记录" :isLoad="isLoad" @change-page="changePage"></Table>

    <!-- 详情弹窗 -->
    <Modal v-model="details" :mask-closable="false" footer-hide width="630" class="modal">
      <p slot="header" class="header">产品详情</p>
      <div class="body">
        <p>采购信息</p>
        <div class="info">
          <div class="left">
            <div class="item">
              <div class="title">采购单号：</div>
              <div>{{ this.detailsData.a }}</div>
            </div>
            <div class="item">
              <div class="title">供应商：</div>
              <div>{{ this.detailsData.c }}</div>
            </div>
          </div>
          <div class="right">
            <div class="item">
              <div class="title">订单时间：</div>
              <div>{{ this.detailsData.b }}</div>
            </div>
            <div class="item">
              <div class="title">采购单价：</div>
              <div>{{ this.detailsData.d }}</div>
            </div>
          </div>
        </div>
        <p>入库信息</p>
        <div class="info">
          <div class="left">
            <div class="item">
              <div class="title">入库单号：</div>
              <div>{{ this.detailsData.a }}</div>
            </div>
            <div class="item">
              <div class="title">入库人：</div>
              <div>{{ this.detailsData.c }}</div>
            </div>
          </div>
          <div class="right">
            <div class="item">
              <div class="title">入库时间：</div>
              <div>{{ this.detailsData.b }}</div>
            </div>
            <div class="item">
              <div class="title">入库数量：</div>
              <div>{{ this.detailsData.d }}</div>
            </div>
          </div>
        </div>
        <p>销售信息</p>
        <div class="info">
          <div class="left">
            <div class="item">
              <div class="title">销售单号：</div>
              <div>{{ this.detailsData.a }}</div>
            </div>
            <div class="item">
              <div class="title">客户：</div>
              <div>{{ this.detailsData.c }}</div>
            </div>
          </div>
          <div class="right">
            <div class="item">
              <div class="title">订单时间：</div>
              <div>{{ this.detailsData.b }}</div>
            </div>
            <div class="item">
              <div class="title">销售单价：</div>
              <div>{{ this.detailsData.d }}</div>
            </div>
          </div>
        </div>
        <p>出库信息</p>
        <div class="info">
          <div class="left">
            <div class="item">
              <div class="title">出库单号：</div>
              <div>{{ this.detailsData.a }}</div>
            </div>
            <div class="item">
              <div class="title">出库人：</div>
              <div>{{ this.detailsData.c }}</div>
            </div>
          </div>
          <div class="right">
            <div class="item">
              <div class="title">出库时间：</div>
              <div>{{ this.detailsData.b }}</div>
            </div>
            <div class="item">
              <div class="title">出库数量：</div>
              <div>{{ this.detailsData.d }}</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <tips-component showModal="delete" v-if="tipStatus" @cancleBtn="tipStatus = false" @sureBrn="sureDelete"></tips-component>
  </div>
</template>

<script>
import Table from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'productTraceabilitySheet',
  components: {
    Table,
    TipsComponent
  },
  data() {
    return {
      tipStatus: false,
      detailInfo: {},
      // 详情数据
      detailsData: {
        a: 'CG202008050001',
        b: '2020-09-12 12:00:00',
        c: '上海微创医疗器械集团有限公司',
        d: '2000.00'
      },
      // 详情弹窗
      details: false,
      // 加载中
      isLoad: false,

      statusCode: [
        { name: '草稿', code: '0' },
        { name: '待审核', code: '1' },
        { name: '审核通过', code: '2' },
        { name: '审核驳回', code: '3' }
      ],
      productName: [],
      // 分页
      total: 1,
      pages: {
        page: 1,
        rows: 10
      },
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65
        },
        {
          title: '产品名称',
          // key: 'name',
          align: 'center',
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.goDetails(param.row, 1)
                    }
                  }
                },
                param.row.name
              )
            ])
          }
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center'
        },
        {
          title: '生产厂家',
          key: 'factory_name',
          align: 'center'
        },
        {
          title: '有效期',
          key: 'end_time',
          sortable: 'custom',
          align: 'center'
        },
        {
          title: '状态',
          key: 'status_str',
          align: 'center',
          width: 180
        },
        {
          title: '操作',
          align: 'center',
          width: 220,
          render: (h, param) => {
            if (this.showChange == 1) {
              switch (param.row.status_code) {
                case 0:
                  return h('div', [
                    h(
                      'span',
                      {
                        // style: {
                        //   color: '#389AFC',
                        //   fontSize: '14px',
                        //   cursor: 'pointer'
                        // },
                        class: {
                          disableUse: this.modifyAble,
                          tableFont: true
                        },
                        on: {
                          click: () => {
                            this.goDetails(param.row, 2)
                          }
                        }
                      },
                      '编辑'
                    ),
                    // h(
                    //   'span',
                    //   {
                    //     style: {
                    //       /* color: '#389AFC',
                    //       fontSize: '14px',
                    //       cursor: 'pointer', */
                    //       marginLeft: '30px'
                    //     },
                    //     class: {
                    //       disableUse: this.deleteAble,
                    //       tableFont: true
                    //     },
                    //     on: {
                    //       click: () => {
                    //         this.deleteRow(param.row)
                    //       }
                    //     }
                    //   },
                    //   '删除'
                    // )
                  ])
                case 1:
                  return h('div', [
                    h(
                      'span',
                      {
                        /* style: {
                          color: '#389AFC',
                          cursor: 'pointer'
                        }, */
                        class: {
                          disableUse: this.modifyAble,
                          tableFont: true
                        },
                        on: {
                          click: () => {
                            this.goDetails(param.row, 2)
                          }
                        }
                      },
                      '编辑'
                    )
                  ])
                case 2:
                  return h('div', [
                    h(
                      'span',
                      {
                        /* style: {
                          color: '#389AFC',
                          cursor: 'pointer'
                        }, */
                        class: {
                          disableUse: this.modifyAble,
                          tableFont: true
                        },
                        on: {
                          click: () => {
                            this.goDetails(param.row, 2)
                          }
                        }
                      },
                      '编辑'
                    ),
                    h(
                      'span',
                      {
                        style: {
                          color: '#389AFC',
                          cursor: 'pointer',
                          marginLeft: '30px'
                        },
                        on: {
                          click: () => {
                            this.upload(param.row)
                          }
                        }
                      },
                      '导出审批表'
                    )
                  ])
                case 3:
                  return h('div', [
                    h(
                      'span',
                      {
                        /* style: {
                          color: '#389AFC',
                          cursor: 'pointer'
                        }, */
                        class: {
                          disableUse: this.modifyAble,
                          tableFont: true
                        },
                        on: {
                          click: () => {
                            this.goDetails(param.row, 2)
                          }
                        }
                      },
                      '编辑'
                    ),
                    // h(
                    //   'span',
                    //   {
                    //     style: {
                    //       /* color: '#389AFC',
                    //       fontSize: '14px',
                    //       cursor: 'pointer', */
                    //       marginLeft: '30px'
                    //     },
                    //     class: {
                    //       disableUse: this.deleteAble,
                    //       tableFont: true
                    //     },
                    //     on: {
                    //       click: () => {
                    //         this.deleteRow(param.row)
                    //       }
                    //     }
                    //   },
                    //   '删除'
                    // )
                  ])
                default:
                  return h('div', [
                    h(
                      'span',
                      {
                        style: {
                          color: '#389AFC',
                          cursor: 'pointer'
                        }
                      },
                      '-'
                    )
                  ])
              }
            } else {
              switch (param.row.status_code) {
                case 2:
                  return h('div', [
                    h(
                      'span',
                      {
                        style: {
                          color: '#389AFC',
                          cursor: 'pointer'
                        },
                        on: {
                          click: () => {
                            this.upload(param.row)
                          }
                        }
                      },
                      '导出审批表'
                    )
                  ])
                default:
                  return h('div', [
                    h(
                      'span',
                      {
                        style: {
                          color: '#389AFC'
                        }
                      },
                      '-'
                    )
                  ])
              }
            }
          }
        }
      ],
      // 表单拉下选项
      thirdList: [],
      // 查询参数
      options: {},
      queryFrom: {
        productName: '',
        end_time: [],
        end_time_start: '',
        end_time_end: '',
        status_code: ''
      },
      productSelect: [],
      showChange: 1,
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false // 查找权限
    }
  },
  activated () {
    if(sessionStorage.getItem('updateList') == 0) {
      this.getProductList()
      sessionStorage.setItem('updateList','1')
    }
    
  },
  mounted() {
    this.showChange = localStorage.getItem('showChange') || 2
    if (this.$route.query.show) {
      this.queryFrom.status_code = '1'
    }
    this.query()
    this.getProductList()
    this.searchProduct()
    this.getProductSelect()
    this.getRightVisibal()
  },
  methods: {
    sortChange(data) {
      this.key = data.key
      this.order = data.order
      this.getProductList()
    },
    getProductSelect() {
      this.$http.get(this.$api.getProductNameSelect).then(res => {
        this.productSelect = res.data
      })
      /* } */
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.queryFrom.end_time_start = e
        that.queryFrom.end_time_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.end_time_start)
          }
        }
      } else {
        this.queryFrom.end_time_end = e
      }
    },
    // 确认删除
    sureDelete() {
      this.$http.DeleteAll(this.$api.productProductDelete, { review_id: this.detailInfo.review_id }, true).then(res => {
        this.query()
        this.tipStatus = false
      })
    },
    // 点击删除
    deleteRow(row) {
      this.detailInfo = row
      this.tipStatus = true
    },
    // 导出审批表
    upload(row) {
      // this.$Message.warning('功能开发中...')
      this.$http.downFile(this.$api.productBusinessReviewExport, { review_id: row.review_id }, true).then(res => {
        let csvData = new Blob([res.data], { type: 'application/x-xlsx' }) // response.data要导出的内容
        let file_name = '审批表' + '.xls'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(csvData, file_name)
        } else {
          var a = document.createElement('a')
          var url = window.URL.createObjectURL(csvData)
          a.href = url
          // eslint-disable-next-line camelcase
          a.download = file_name
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        }
      })
    },
    // 编辑 详情
    goDetails(row, num) {
      this.$router.push({
        path: '/editProduct',
        query: {
          id: row.review_id,
          status: num // 1是详情 2是编辑
        }
      })
    },
    // 新增
    add() {
      this.$router.push({
        path: '/addProduct'
      })
    },
    // 翻页
    changePage(e) {
      this.pages.page = e
      this.getProductList()
    },
    // 产品名称下拉
    searchProduct() {
      this.$http.get(this.$api.searchProduct).then(res => {
        this.productName = res.data
      })
    },
    query() {
      this.pages.page = 1
      this.getProductList()
    },
    // 产品列表
    getProductList() {
      let data = {
        page: this.pages.page,
        rows: this.pages.rows,
        product_name: this.queryFrom.productName,
        status_code: this.queryFrom.status_code,
        end_time_start: this.queryFrom.end_time_start,
        end_time_end: this.queryFrom.end_time_end,
        sort_str: this.key,
        sort_type: this.order
      }
      this.$http.get(this.$api.getProductList, data).then(res => {
        this.listData = res.data.rows
        this.total = res.data.total
        // 格式化有效期
        this.listData.forEach(item => {
          item.end_time = item.end_time ? this.$moment(new Date(item.end_time * 1000)).format('YYYY-MM-DD') : '-'
          item.licence_code = item.licence_code ? item.licence_code : item.record_code
        })
      })
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = rightStr.search
    }
  }
}
</script>

<style scoped lang="less">
.form {
  height: 36px;
  margin-bottom: 20px;
  .label {
    color: #000;
  }
}
.marginRight60 {
  margin-right: 20px;
}
.centeFont {
  margin: 0 10px;
}
.modal {
  color: #333333;
  .header {
    font-size: 18px;
    font-weight: bold;
  }
  .body {
    p {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: bold;
    }
    .info {
      display: flex;
      .left {
        flex: 6;
      }
      .right {
        flex: 4;
      }
      .item {
        display: flex;
        margin-bottom: 20px;

        .title {
          width: 80px;
        }
      }
    }
  }
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
</style>
